import { addMinutes, startOfDay } from "date-fns";
import isPast from "date-fns/isPast";

export interface SchedulerMinutesTimeOption {
  label: string;
  minutes: number;
  isDisabled: boolean;
}

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;

function generateTimeOption(
  index: number,
  minuteStep: number,
  date: Date | null | undefined,
) {
  const elapsedMinutes = index * minuteStep;
  const hours = Math.floor(elapsedMinutes / MINUTES_IN_HOUR);
  const hoursIn12HourFormat = hours % 12;
  const hoursAsString =
    hoursIn12HourFormat < 10 ? "0" + hoursIn12HourFormat : hoursIn12HourFormat;
  const hoursAsStringWithout00 = hoursAsString === "00" ? "12" : hoursAsString;
  const minutes = elapsedMinutes % MINUTES_IN_HOUR;
  const minutesAsString = minutes < 10 ? "0" + minutes : minutes;
  const ampmSuffix = hours < 12 ? "am" : "pm";
  const option: SchedulerMinutesTimeOption = {
    label: `${hoursAsStringWithout00}:${minutesAsString}${ampmSuffix}`,
    minutes: elapsedMinutes,
    isDisabled: date
      ? isPast(addMinutes(startOfDay(date), elapsedMinutes))
      : false,
  };
  return option;
}

export function generateTimeOptions(
  minuteStep: number,
  date: Date | null | undefined,
) {
  const steps = Math.floor(MINUTES_IN_DAY / minuteStep);
  return Array(steps)
    .join()
    .split(",")
    .map((num, index) => {
      return generateTimeOption(index, minuteStep, date);
    });
}
